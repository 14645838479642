import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ModuleStatus } from 'types/ModuleStatus';
import { MODULE_STATUS } from 'constants/modules';
import {
  AuthorizationErrorResponse,
  SocialLoginDoneResponse,
} from 'types/SocialLogin';
import {
  FACEBOOK_NEW_PROFILE_SCOPES,
  INSTAGRAM_NEW_PROFILE_SCOPES,
  SocialLoginError,
  THREADS_NEW_PROFILE_SCOPES,
} from 'constants/socialLogin';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import Logger from 'utils/logger';
import { openPopup } from 'utils/url';
import AppConfig from 'app/config/app';
import SocialLoginApi from 'app/modules/socialLogin/api';
import PageTypeUtils from 'app/utils/pageType';
import { PageTypeName } from 'types/Page';

type SocialLoginContextValue = {
  status: ModuleStatus;
  authorization: SocialLoginDoneResponse;
  error: AuthorizationErrorResponse | undefined;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  authorize: (options: {
    pageId?: number;
    pageType: number;
    scopes?: string[];
  }) => void;
  reset: () => void;
};

const SocialLoginContext = createContext<SocialLoginContextValue | undefined>(
  undefined,
);

function getDefaultScopes(pageType: number) {
  if (pageType === TYPE.FACEBOOK) {
    return FACEBOOK_NEW_PROFILE_SCOPES;
  }

  if (pageType === TYPE.INSTAGRAM) {
    return INSTAGRAM_NEW_PROFILE_SCOPES;
  }

  if (pageType === TYPE.THREADS) {
    return THREADS_NEW_PROFILE_SCOPES;
  }

  return [];
}

const SocialLoginContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [status, setStatus] = useState<ModuleStatus>(MODULE_STATUS.Idle);
  const [authorization, setAuthorization] = useState<any>();
  const [error, setError] = useState<AuthorizationErrorResponse | undefined>();
  const popup = useRef<Window | null>(null);
  const broadcastChannel = useRef(
    new BroadcastChannel(AppConfig.SOCIAL_LOGIN_BROADCAST_CHANNEL_NAME),
  );
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const authorizationOptions = useRef<{
    pageType: number | null;
    scopes: string[];
  }>({
    pageType: null,
    scopes: [],
  });

  useEffect(() => {
    const eventMessageListener = (
      event: MessageEvent<{
        data: SocialLoginDoneResponse['data'] | { reason: string };
        type: string;
      }>,
    ) => {
      const { data } = event;
      Logger.log('Recieved social login data', data);
      try {
        if (data.type === undefined || status !== MODULE_STATUS.Loading) {
          return;
        }

        if (!data.type.includes('login-done')) {
          throw new Error('Social login failed');
        }

        const authorizationSucceededData = {
          data: data.data,
          authorizationOptions: authorizationOptions.current,
        };

        setAuthorization(authorizationSucceededData);
        setStatus(MODULE_STATUS.Succeeded);
        popup.current?.close();
      } catch (e) {
        const error = {
          reason:
            (data.data as { reason?: string })?.reason ||
            (e instanceof Error && e.message) ||
            SocialLoginError.unableToAuthorize,
          authorizationOptions: authorizationOptions.current,
        };

        Logger.error(error);
        setError(error);
        setStatus(MODULE_STATUS.Failed);
      }
    };

    broadcastChannel.current.onmessage = eventMessageListener;

    const popupClosedListener = () => {
      const isWatchDisabled =
        authorizationOptions.current.pageType &&
        (
          [
            TYPE.THREADS,
            TYPE.TWITTER,
            TYPE.GOOGLE_MY_BUSINESS,
            TYPE.PINTEREST,
          ] as number[]
        ).includes(authorizationOptions.current.pageType);

      if (isWatchDisabled) return;

      const isClosedWhileLoading =
        (popup.current?.closed || popup.current === null) &&
        status === MODULE_STATUS.Loading;

      if (isClosedWhileLoading) {
        const error = {
          reason: SocialLoginError.socialLoginClosed,
          authorizationOptions: authorizationOptions.current,
        };

        Logger.error(SocialLoginError.socialLoginClosed);
        setError(error);
        setStatus(MODULE_STATUS.Failed);
      }
    };

    interval.current = setInterval(popupClosedListener, 1000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [status]);

  useEffect(() => {
    return () => {
      popup.current?.close();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      broadcastChannel.current.close();
    };
  }, [popup, broadcastChannel]);

  async function authorize(options: {
    pageType: number;
    pageId?: number;
    scopes?: string[];
  }) {
    try {
      const scopes = options.scopes
        ? options.scopes
        : getDefaultScopes(options.pageType);

      authorizationOptions.current.pageType = options?.pageType;
      authorizationOptions.current.scopes = scopes;

      if (!authorizationOptions.current.pageType) {
        throw new Error('Page type not defined');
      }

      setStatus(MODULE_STATUS.Loading);
      setAuthorization(undefined);
      setError(undefined);

      const socialLoginUrlResponse = await SocialLoginApi.requestLoginUrl(
        PageTypeUtils.getName(
          authorizationOptions.current.pageType as number,
        ) as PageTypeName,
        {
          scopes: authorizationOptions.current?.scopes,
        },
      );

      popup.current = openPopup(socialLoginUrlResponse.loginUrl);
    } catch (e) {
      const error = {
        reason:
          (e instanceof Error && e.message) ||
          SocialLoginError.unableToAuthorize,
        authorizationOptions: authorizationOptions.current,
      };

      Logger.error(error);
      setError(error);
      setStatus(MODULE_STATUS.Failed);
    }
  }

  const reset = () => {
    setStatus(MODULE_STATUS.Idle);
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  return (
    <SocialLoginContext.Provider
      value={{
        status,
        authorization,
        error,
        isSuccess: status === MODULE_STATUS.Succeeded,
        isLoading: status === MODULE_STATUS.Loading,
        isError: status === MODULE_STATUS.Failed,
        authorize,
        reset,
      }}
    >
      {children}
    </SocialLoginContext.Provider>
  );
};

export default SocialLoginContextProvider;

export const useSocialLoginContext = () => {
  const context = useContext(SocialLoginContext);

  if (!context) {
    throw new Error(
      'useSocialLoginContext must be used within a SocialLoginContextProvider',
    );
  }

  return context;
};
